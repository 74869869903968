import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

export const Course = () => {
  const search = useLocation().search
  const courseURL = window.decodeURIComponent(new URLSearchParams(search).get('courseURL') || '')

  const [activeDocument, setActiveDocument] = useState<string | null>(null)
  useEffect(() => {
    if (courseURL) {
      console.log('courseURL', courseURL)
      axios.get(courseURL).then((res) => {
        setActiveDocument(res.data)
      })
    }
  }, [courseURL])

  return !!activeDocument ? <div dangerouslySetInnerHTML={{ __html: activeDocument }}></div> : <></>
}
