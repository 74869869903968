/* eslint-disable jsx-a11y/anchor-is-valid */
import { ObjectMeta } from 'ali-oss'
import { useEffect, useState } from 'react'
import './App.css'
import { OSSClient } from './oss'
import { List } from 'antd'
import 'antd/dist/antd.css'

function App() {
  const [activeDir, setActiveDir] = useState('')
  const [dirs, setDirs] = useState<string[]>([])
  const [courses, setCourses] = useState<ObjectMeta[]>([])
  const getCourses = async (dir: string) => {
    let result = await OSSClient.list({ prefix: dir, 'max-keys': 1000, delimiter: '/' }, {})
    setCourses(result.objects)
  }

  const getDir = async () => {
    let result = await OSSClient.list({ 'max-keys': 1000, delimiter: '/' }, {})
    setDirs(result.prefixes)
    const [first] = result.prefixes
    if (first) {
      setActiveDir(first)
    }
  }

  const changeDir = (dir: string) => {
    setActiveDir(dir)
  }

  useEffect(() => {
    getDir()
  }, [])

  useEffect(() => {
    if (activeDir) {
      getCourses(activeDir)
    }
  }, [activeDir])

  const onchangeActiveDocument = async (activeCourse: ObjectMeta) => {
    window.open(`/course?courseURL=${window.encodeURIComponent(activeCourse.url)}`)
  }

  return (
    <div id='app' className='app'>
      <List
        dataSource={dirs}
        size='small'
        header={<div>文件夹</div>}
        bordered
        renderItem={(item) => (
          <List.Item onClick={() => changeDir(item)}>
            <a>{item}</a>
          </List.Item>
        )}
      />
      {activeDir && (
        <ul id='content'>
          <List
            dataSource={courses}
            size='small'
            bordered
            header={<div>内容</div>}
            renderItem={(item) => (
              <List.Item onClick={() => onchangeActiveDocument(item)}>
                <a>{item.name}</a>
              </List.Item>
            )}
          />
        </ul>
      )}
    </div>
  )
}

export default App
